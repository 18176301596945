.form-container {
    background: #eee;
    padding: 45px 30px;
    border-radius: 10px;
}

.form-container.login {
    background: #fff;
    width: 25%;
}

.login-logo {
    height: 100px;
}

.login-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.centered-item {
    display: flex;
    justify-content: center;
}

.forgot-password {
    color: var(--dark-blue);
    text-decoration: none;
    padding-top: 10px;
    text-align: center;
}

.forgot-password:hover {
    color: #000a61b7;
}

.full-width {
    width: 100%;
    text-transform: uppercase;
}

.form-label-login {
    color: var(--dark-blue);
    margin-bottom: 3px;
}

.login-input-container {
    position: relative;
}

.login-input-icon {
    color: var(--dark-blue);
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 12px;
}

.divider {
    position: absolute;
    left: 38px;
    top: 12px;
    height: 50%;
    width: 1px;
    background-color: #ccd1e0;
}

.form-control.login {
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}