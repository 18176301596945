.page-title {
    color: var(--darker-blue);
    font-weight: 600;
    margin-bottom: 0;
}

.filters-expand {
    position: relative;
    z-index: 20;
    overflow: hidden;
    margin-bottom: 20px;
    transition: all .1s linear;
}

.filters-expand.close {
    max-height: 40px;
    height: 40px;
}

.filters-expand.open {  
    max-height: 105px;
    height: 105px;
    overflow: visible;
}

.open-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border: 1px solid #e2e8f0;
    background-color: var(--white);
    color: #3f478f;
    border-radius: 8px;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s linear;
}

.open-filters.open {
    display: none;
    background-color: #3f478f;
    color: var(--white);
    border: 1px solid #3f478f;
}

.reset-filters {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border: 1px solid #e2e8f0;
    background-color: #fc5e5e;
    border-radius: 8px;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s linear;
}

.reset-filters.open {
    display: flex;
    color: var(--white);
}

.open-filters > svg, .reset-filters > svg {
    width: 15px;
    height: 15px;
}

.dropdown-filters {
    display: flex;
    align-items: center;
    margin-top: 22px;
}

.filters-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.input-styling {
    border-radius: 5px;
    height: 40px;
    padding: 5px;
    padding-left: 50px;
    border: 1px solid #cccccc;
    background-color: var(--white);
    box-shadow: none;
    transition: all .2s linear;
}

.input-styling.open {
    border-bottom-left-radius: 0px;
}

.input-styling.on {
    border-radius: 8px;
    height: 40px;
    padding: 5px;
    padding-left: 50px;
    border: 1px solid #e2e8f0;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
    
}

.search.divider {
    position: absolute;
    left: 40px;
    top: 11px;
    height: 50%;
    width: 1px;
    background-color: #ccd1e0;
}

.input-styling:focus {
    outline: none;
}

.input-container {
    position: relative;
    margin-left: 3px;
}

.search-icon-container {
    position: absolute;
    left: 8px;
    top: 18%;
    cursor: pointer;
}

.search-icon-container > svg {
    width: 23px;
    height: 23px;
    color: #000650;
}

.select-styling {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #f3f3f3;
    background-color: var(--white);
    box-shadow: none;
}

.select-styling.enabled {
    background-color: #f6fdf9;
    color: #22c55e;
    border: 1px solid #f6fdf9;
}

.select-styling.disabled {
    background-color: #fff7f5;
    color: #f07f58;
    border: 1px solid #fff7f5;
}

.select-styling.pending {
    background-color: #fff7f5;
    color: #f07f58;
    border: 1px solid #fff7f5;
}

.select-styling.blocked {
    background-color: #fcf3f3;
    color: #bb3434;
    border: 1px solid #fcf3f3;
}

.select-styling.r-m-2 {
    margin-right: 10px;
}

.r-m-2 {
    margin-right: 10px;
}


.search-options-dropdown {
    position: absolute;
    z-index: 10;
    transition: all .2s linear;
    height: 0;
    max-height: 0;
    overflow: hidden;
}

.search-options-dropdown.open {
    height: 120px;
    max-height: 150px;
}

.search-options-dropdown > ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    background: var(--white);
    border: 1px solid #e2e8f0;
    border-top: 0px;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 0px 5px;
}

.search-options-dropdown > ul > li {
    color: #000650;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    padding: 5px 0px;
}

.search-options-dropdown > ul > li:last-child {
    border-bottom: none;
}

.send-search.off {
    display: none;
    position: absolute;
    background-color: var(--white);
    left: 100%;
    top: 0px;
    height: 40px;
    width: 30px;
    border: 1px solid #e2e8f0;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}

.send-search.on {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    left: 100%;
    top: 0px;
    height: 40px;
    width: 30px;
    border: 1px solid #e2e8f0;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}
