@font-face {
    font-family: 'Inter';
    src: url(../fonts/inter/Inter-VariableFont_slnt\,wght.ttf) format("truetype-variations");
  }
/* @import url("./variables.css"); */
@import url("./variables.css");

html {
font-family: 'Inter', sans-serif !important;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif !important;
}

table{
    width: 100%;
    margin-bottom: 10px;
    border-top: 1px solid #f3f3f3;
}

td{
    padding: 10px 0px;
    color: var(--darker-blue);
}

th {
    color: var(--darker-blue);
    padding-top: 20px;
    padding-bottom: 20px;
}

::placeholder {
    color: hsl(0, 0%, 60%);
    opacity: 1; /* Firefox */
    font-size: 14px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: hsl(0, 0%, 60%);

    font-size: 14px;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: hsl(0, 0%, 60%);

    font-size: 14px;
}

.login-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--dark-blue);
}

.session-bg {
    height: calc(100% - 69px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.general-bg {
    display: flex;
    background: var(--white);
}

.body-height {
    flex-grow: 1;
    height: 100vh;
}

.general-side-p {
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.general-header-side-p {
    padding-left: 30px;
    padding-right: 30px;
}

.error-msg {
    font-size: 10px;
    color: #fc5e5e;
}