.spinner-screen {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-dual-ring-lg {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring-md {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.lds-dual-ring-sm {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.lds-dual-ring-lg:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-sm:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}