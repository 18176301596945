
.side-navbar {
    width: 265px;
    background-color: var(--dark-blue);
    color: var(--white);
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 23px;
}

.side-navbar-brand {
    font-size: 25px;
    text-decoration: none;
    color: var(--white);
    font-weight: 600;
    margin-bottom: 30px;
}

.side-navbar-brand:hover {
    color: var(--white);
}

.margin-left-nav {
    padding-left: 25px !important;
    padding-right: 25px !important
}

.nav-item {
    cursor: pointer;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.nav-link-list  {
    padding-left: 0;
}

.nav-link-list .dropdown-link {
    list-style-type: none;
    border-radius: 8px;
    height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inside-ul-nav-link-list {
    margin-left: 25px;
    border-left: 1px solid #e2e8f033;
    padding-left: 10px;
}

.inside-ul-nav-link-list a{
    text-decoration: none;
    color: var(--white);
    font-size: 14px;
}

.nav-link-list .nav-item {
    list-style-type: none;
    padding: 16px 8px;
    border-radius: 8px;
    color: var(--white);
    height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    transition: all .2s linear;
    text-decoration: none;
}

.nav-img {
    margin-right: 10px;
    fill: #a9b1d2 !important;
}

.nav-link-list .nav-item a {
    color: var(--white);
    text-decoration: none;
}

.nav-link-list .nav-item.active {
    background-color: var(--light-blue);
    text-decoration: none;
    color: var(--white);
}

.dropdown-arrow {
    transition: all .2s linear;
}

.dropdown-arrow.close {
    transition: all .2s linear;
}

.dropdown-arrow.open {
    transform: rotate(-180deg);
}

.nav-link {
    display: flex;
    align-items: center;
    color: var(--white);
}

/* .nav-link img {
    margin-right: 10px;
} */

.dropdown-links {
    overflow: hidden;
    transition: all .3s linear;
    list-style-type: none;
    padding-left: 0;
}

.dropdown-links.close {
    max-height: 0px;
    height: auto;
}

.dropdown-links.open {
    max-height: 170px;
    height: auto;
}

.dropdown-link a{
    text-decoration: none;
    text-transform: capitalize;
    color: var(--white);
    transition: all .2s linear;
}

.dropdown-link.active a{
    color: var(--light-blue);
}

.dropdown-link.active img{
    color: var(--white);
}

.dropdown-link img {
    margin-right: 10px;
}