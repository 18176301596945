.pagination > .all-pages-list
 {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
 }

.current-page-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-blue);
    border: none;
    border-radius: 50%;
    padding: 10px;
    background: transparent;
    width: 30px;
    height: 30px;
    transition: all .2s linear;
}

.current-page-btn.active {
    background: var(--light-blue);
    color: var(--white);
}