.account-status-edit {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--transparent);
    box-shadow: 0px 0px 5px 0px var(--box-shadow);
    border: none;
    border-radius: 8px;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--dark-blue);
}

.account-status-edit svg {
    font-size: 16px;
}

.auth-type-icons {
    font-size: 24px;
    margin-left: 15px;
}

.current-plan {
    text-transform: capitalize;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 8px;
    color: #b4b4b4;
    margin-left: 15px;
}

.half-full-width {
    width: 50%;
    color: var(--darker-blue);
}

.blue-text {
    color: var(--darker-blue);
}

.flex-align {
    display: flex;
    align-items: center;
}

.margin-r-2 {
    margin-right: 10px;
}