
.admin-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
}

.user-info-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 235px;
    cursor: pointer;
}

.admin-bar-dropdown {
    position: absolute;
    overflow: hidden;
    top: 134%;
    left: 0;
    height: 0;
    max-height: 0;
    width: 235px;
    background: transparent;
    transition: all .2s linear;
}

.admin-bar-dropdown.close {
    height: auto;
    max-height: 0;
}

.admin-bar-dropdown.open {
    height: auto;
    max-height: 150px;
    box-shadow: 0px 6px 9px 0px #a0a0a01a;
    z-index: 25;
}

.user-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #282b6a;
    margin-right: 5px;
    text-transform: uppercase;
    color: var(--white);
    font-weight: 700;
    font-size: 18px;
}

.user-name {
    color: #282b6a;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 700;
    text-transform: capitalize;
}

.user-role {
    color: #81839d;
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 600;
    text-transform: capitalize;
}

.admin-dropdonw-list {
    padding: 10px 0px;
    margin-bottom: 0;
    border: solid 1px #edf2f7;
    border-top: none;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: #fff;
}

.admin-dropdown-item {
    color: #282b6a;
    font-size: 12px;
    padding: 6px 10px;
    list-style-type: none;
    background-color: transparent;
    transition: all .2s linear;
}
.admin-dropdown-item:hover {
    color: var(--light-blue);
}

.go-back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #f8f9fd;
    border-radius: 50%;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.go-back-btn > svg {
    color: var(--darker-blue);
    font-size: 18px;
}