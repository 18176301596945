.full-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.container-full-width {
    width: 100%;
}

.upper-modal-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.admin-status {
    margin-bottom: 0;
    width: 96px;
    height: 36px;
    padding: 6px 15px;
    border-radius: 8px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.admin-role {
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--darker-blue);
    font-size: 14px;
}

.admin-status.disabled { 
    background-color: #fff7f5;
    color: #f07f58;
}

.admin-status.enabled { 
    background-color: #f6fdf9;
    color: #22c55e;
}

.admin-status.blocked { 
    background-color: #fcf3f3;
    color: #bb3434;
}

.edit-status-button {
    width: 100px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid var(--light-blue);
    transition: all .2s linear;
    color: var(--light-blue);
}

.edit-status-button:hover {
    background: var(--light-blue);
    color: var(--white);
}

.edit-status-button.disabled {
    color: #f8f9fa;
    pointer-events: none;
    background-color: transparent;
    border-color: #f8f9fa;
    opacity: 0.65;
}

.change-sub-admin-status {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
}

.change-sub-admin-status > span{
    color: var(--darker-blue);
}

.select-options {
    text-transform: capitalize;
}