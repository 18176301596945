.date-range-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
}

.date-range-btn, .reset-date-range {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    border-radius: 4px;
    background: #fff;
    color: #808080;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #cccccc;
    transition: all .3s;
    height: 38px;
}

.reset-date-range {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: 0px;
}

.date-range-btn {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-left: 10px;
}

.date-range-btn.open {
    color: var(--darker-blue);
}

.spacing-from-icon {
    margin-right: 5px;
}

.datepicker-divider {
    position: absolute;
    right: 34px;
    top: 9px;
    height: 55%;
    width: 1px;
    background-color: #ccd1e0;
}

.rdrDefinedRangesWrapper {
    width: 0px !important;
}