.container-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-admin-field {
    position: relative;
    display: flex;
    align-items: center;
}

.error-msg-absolute {
    position: absolute;
    bottom: 110%;
    right: 30%;
    color: #fc5e5e;
    font-size: 10px;
}

.create-admin-label {
    color: var(--darker-blue);
    width: 250px;
}

.form-control-field {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color:var(--white);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control-field:focus {
    outline: 0;
    border: 1px solid #ced4da;
    box-shadow: 0 0 0 .25rem #ced4da3d;
}

.form-control-field.error-msg-field {
    border:1px solid #fc5e5e;
    outline: 0;
    box-shadow: 0 0 0 .25rem #fc5e5e3f
}