.modal-layer {
    position: fixed;
    inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 999;
    padding: 40px 20px 20px;
  }
  
  .modal-real-content {
    width: 40%;
    height: fit-content;
    background-color: var(--white);
    color: #000;
    padding: 25px 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    cursor: pointer;
    background: #e92769;
    width: 30px;
    height: 25px;
  }

  .title-modal {
    margin-bottom: 60px;
    color: var(--darker-blue);
    font-weight: 600;
  }