.list-block {
    border-bottom: 1px solid #f3f3f3;
}

.list-block:first-child {
    border-top: 1px solid #f3f3f3;
    margin-top: 10px;
}

.list-block-container {
    display: flex;
    align-items: center;
}

.list-block-container.details {
    cursor: pointer;
}

.list-block-container > .user-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.list-block-size.created-by-username {
    text-transform: capitalize;
}

.status {
    margin-bottom: 0;
    width: 96px;
    height: 36px;
    padding: 6px 15px;
    border-radius: 8px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.status.pending { 
    background-color: #fff7f5;
    color: #f07f58;
}

.status.disabled { 
    background-color: #fff7f5;
    color: #f07f58;
}

.status.enabled { 
    background-color: #f6fdf9;
    color: #22c55e;
}

.status.canceled { 
    background-color: #fcf3f3;
    color: #bb3434;
}

.status.blocked { 
    background-color: #fcf3f3;
    color: #bb3434;
}

.status.to_activate { 
    background-color: #fcf3f3;
    color: #bb3434;
    
    box-shadow: 0 0 0 0 #af2c2c21;
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #af2c2c21;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px #b4323200;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #b4323200;
	}
}

.status.detail {
    background-color: var(--white);
    color: var(--light-blue);
    border: solid 1px #edf2f7;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s linear;
}

.status.detail.open {
    color: var(--white);
    background-color: var(--light-blue);
    border: solid 1px var(--light-blue);
    cursor: pointer;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.create-user-btn {
    border-radius: 4px;
    background-color: var(--light-blue);
    color: var(--white);
    border: none;
    padding: 5px 10px;
}

.edit-options-dropdown {
    position: absolute;
    top: 79%;
    width: 96px;
    background: transparent;
    height: 0;
    max-height: 0;
    overflow: hidden;
    transition: all .2s linear;
}

.edit-options-dropdown.close {
    height: auto;
    max-height: 0;
}

.edit-options-dropdown.open {
    height: auto;
    max-height: 250px;
    box-shadow: 0px 6px 9px 0px #a0a0a01a;
    z-index: 10;
}

.edit-options-list {
    list-style: none;
    background: #fff;
    padding: 0px 10px;
    margin-bottom: 0;
    border: solid 1px #edf2f7;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.edit-options-list-item {
    padding: 7px 0px;
    border-bottom: 1px solid #a0a0a01a;
    cursor: pointer;
    font-size: 14px;
    color: #000650;
    transition: all .2s linear;
}

.edit-options-list-item:last-child {
    border-bottom: none;
}

.edit-options-list-item:hover {
    color: var(--light-blue);
}
